import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMap} from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';

export default function Contact() {
    const { darkMode } = useContext(ThemeContext);
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { 
            opacity: 0,
            y: 20 
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: "easeOut"
            }
        }
    };

    return (
        <section id='contact' className={`py-20 ${darkMode ? 'bg-gray-900' : 'bg-white'} px-[10%]`}>
            <div ref={ref} className="container mx-auto">
                <motion.h2 
                    className={`text-4xl font-bold text-center mb-12 ${darkMode ? 'text-white' : 'text-gray-900'}`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6 }}
                >
                    <span className="text-green-400">&lt;</span>
                    Contact Me
                    <span className="text-green-400">/&gt;</span>
                </motion.h2>

                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="grid md:grid-cols-2 gap-12"
                >
                    {/* Contact Info */}
                    <motion.div variants={itemVariants} className="space-y-8">
                        <div className={`${darkMode ? 'bg-gray-800' : 'bg-gray-100'} p-6 rounded-lg`}>
                            <div className="flex items-center space-x-4">
                                <FontAwesomeIcon icon={faEnvelope} className="text-green-400 text-2xl" />
                                <div>
                                    <h3 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Email</h3>
                                    <p className="text-green-400">davehr1990@gmail.com</p>
                                </div>
                            </div>
                        </div>

                        <div className={`${darkMode ? 'bg-gray-800' : 'bg-gray-100'} p-6 rounded-lg`}>
                            <div className="flex items-center space-x-4">
                                <FontAwesomeIcon icon={faPhone} className="text-green-400 text-2xl" />
                                <div>
                                    <h3 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Phone</h3>
                                    <p className="text-green-400">+251 953 399 990</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${darkMode ? 'bg-gray-800' : 'bg-gray-100'} p-6 rounded-lg`}>
                            <div className="flex items-center space-x-4">
                                <FontAwesomeIcon icon={faMap} className="text-green-400 text-2xl" />
                                
                                <div>
                                    <h3 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Address</h3>
                                    <p className="text-green-400">Addis Ababa, Ethiopia</p>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    {/* Contact Form */}
                    <motion.form 
                        variants={itemVariants} 
                        className="space-y-6"
                        onSubmit={async (e) => {
                            e.preventDefault();
                            const formData = {
                                name: e.target.name.value,
                                email: e.target.email.value,
                                subject: e.target.subject.value,
                                message: e.target.message.value
                            };

                            try {
                                const response = await fetch('/api/contact', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(formData)
                                });

                                if (response.ok) {
                                    alert('Message sent successfully!');
                                    e.target.reset();
                                } else {
                                    alert('Failed to send message. Please try again.');
                                }
                            } catch (error) {
                                console.error('Error:', error);
                                alert('An error occurred. Please try again later.');
                            }
                        }}
                    >
                        <div>
                            <input 
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                required
                                className={`w-full p-3 rounded-lg ${darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100'} focus:outline-none focus:ring-2 focus:ring-green-400`}
                            />
                        </div>
                        <div>
                            <input 
                                type="email"
                                name="email"
                                placeholder="Your Email"
                                required
                                className={`w-full p-3 rounded-lg ${darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100'} focus:outline-none focus:ring-2 focus:ring-green-400`}
                            />
                        </div>
                        <div>
                            <input 
                                type="text"
                                name="subject"
                                placeholder="Subject"
                                required
                                className={`w-full p-3 rounded-lg ${darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100'} focus:outline-none focus:ring-2 focus:ring-green-400`}
                            />
                        </div>
                        <div>
                            <textarea 
                                name="message"
                                placeholder="Your Message"
                                rows="5"
                                required
                                className={`w-full p-3 rounded-lg ${darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100'} focus:outline-none focus:ring-2 focus:ring-green-400`}
                            ></textarea>
                        </div>
                        <motion.button
                            type="submit"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="w-full bg-green-400 text-white py-3 px-6 rounded-lg font-semibold hover:bg-green-500 transition duration-300"
                        >
                            Send Message
                        </motion.button>
                    </motion.form>
                </motion.div>
            </div>
        </section>
    );
}
