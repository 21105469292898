import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';

export default function Skills() {
    const { darkMode } = useContext(ThemeContext);
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });
    const skillIcons = {
        "Node.js": "fa-brands fa-node-js",
        "Python": "fa-brands fa-python", 
        "JavaScript": "fa-brands fa-js",
        "React": "fa-brands fa-react",
        "MongoDB": "fa-solid fa-database",
        "PostgreSQL": "fa-solid fa-database",
        "Express.js": "fa-solid fa-server",
        "PHP": "fa-brands fa-php",
        "Docker": "fa-brands fa-docker",
        "AWS": "fa-brands fa-aws",
        "GraphQL": "fa-solid fa-diagram-project",
        "REST APIs": "fa-solid fa-cloud",
        "Git": "fa-brands fa-git-alt",
        "TypeScript": "fa-solid fa-code",
        "System Design": "fa-solid fa-sitemap"
    };

    const skills = [
        { name: "Node.js", percentage: 95 },
        { name: "Python", percentage: 90 },
        { name: "JavaScript", percentage: 95 },
        { name: "React", percentage: 85 },
        { name: "MongoDB", percentage: 90 },
        { name: "PostgreSQL", percentage: 85 },
        { name: "Express.js", percentage: 90 },
        { name: "PHP", percentage: 80 },
        { name: "Docker", percentage: 75 },
        { name: "AWS", percentage: 80 },
        { name: "GraphQL", percentage: 85 },
        { name: "REST APIs", percentage: 95 },
        { name: "Git", percentage: 90 },
        { name: "TypeScript", percentage: 85 },
        { name: "System Design", percentage: 85 }
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const skillVariants = {
        hidden: { 
            opacity: 0,
            x: -50
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.5,
                ease: "easeOut"
            }
        }
    };
    const progressBarVariants = {
        initial: { width: 0 },
        animate: width => ({
            width: `${width}%`,
            transition: {
                duration: 1.2,
                ease: "easeInOut"
            }
        })
    };

    const skillCardVariants = {
        hidden: {
            opacity: 0,
            y: 20,
            scale: 0.9
        },
        visible: {
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                duration: 0.5,
                ease: "easeOut"
            }
        },
        hover: {
            scale: 1.05,
            boxShadow: "0 10px 20px rgba(0, 128, 0, 0.2)",
            transition: {
                duration: 0.2
            }
        }
    };

    const glowVariants = {
        initial: {
            opacity: 0.5
        },
        animate: {
            opacity: [0.5, 1, 0.5],
            transition: {
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
            }
        }
    };

    return (
        <section className={`py-20 ${darkMode ? 'bg-gray-900' : 'bg-white'} px-[10%]`}>
            <div ref={ref} className="container mx-auto">
                <motion.h2 
                    className={`text-4xl font-bold text-center mb-12 ${darkMode ? 'text-white' : 'text-gray-900'}`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6 }}
                >
                    <span className="text-green-400">&lt;</span>
                    Technical Skills
                    <span className="text-green-400">/&gt;</span>
                </motion.h2>

                <motion.div 
                    className="grid grid-cols-1 md:grid-cols-3 gap-6"
                    variants={containerVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                >
                    {skills.map((skill, index) => (
                        <motion.div 
                            key={index}
                            className={`${darkMode ? 'bg-gray-800' : 'bg-gray-100'} p-4 rounded-lg`}
                            variants={skillVariants}
                        >
                            <div className="flex items-start">
                                <motion.div
                                    className={`w-10 h-10 flex items-center justify-center rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-gray-200'} mr-3`}
                                    variants={glowVariants}
                                    initial="initial"
                                    animate="animate"
                                >
                                    <i className={`text-xl ${
                                        skill.name.toLowerCase().includes('react') ? 'fab fa-react text-blue-400' :
                                        skill.name.toLowerCase().includes('node') ? 'fab fa-node text-green-500' :
                                        skill.name.toLowerCase().includes('python') ? 'fab fa-python text-cyan-400' :
                                        skill.name.toLowerCase().includes('javascript') ? 'fab fa-js text-yellow-300' :
                                        skill.name.toLowerCase().includes('typescript') ? 'fab fa-js text-blue-600' :
                                        skill.name.toLowerCase().includes('mongo') ? 'fas fa-database text-green-400' :
                                        skill.name.toLowerCase().includes('sql') ? 'fas fa-database text-orange-400' :
                                        skill.name.toLowerCase().includes('express') ? 'fas fa-server text-gray-400' :
                                        skill.name.toLowerCase().includes('django') ? 'fab fa-python text-green-600' :
                                        skill.name.toLowerCase().includes('graphql') ? 'fas fa-project-diagram text-pink-500' :
                                        skill.name.toLowerCase().includes('redis') ? 'fas fa-database text-red-500' :
                                        skill.name.toLowerCase().includes('docker') ? 'fab fa-docker text-blue-500' :
                                        skill.name.toLowerCase().includes('kubernetes') ? 'fas fa-dharmachakra text-blue-300' :
                                        skill.name.toLowerCase().includes('aws') ? 'fab fa-aws text-orange-500' :
                                        skill.name.toLowerCase().includes('git') ? 'fab fa-git-alt text-orange-600' :
                                        'fas fa-code text-purple-400'
                                    }`}></i>
                                </motion.div>
                                <div className="flex flex-col gap-2 flex-1">
                                    <div className="flex items-center justify-between">
                                        <span className="text-green-400 font-mono">{skill.name}</span>
                                        <span className="text-blue-400">{skill.percentage}%</span>
                                    </div>
                                    <div className={`w-full ${darkMode ? 'bg-gray-700' : 'bg-gray-300'} rounded-full h-2.5`}>
                                        <motion.div
                                            className={`h-2.5 rounded-full ${
                                                skill.name.toLowerCase().includes('react') ? 'bg-blue-400' :
                                                skill.name.toLowerCase().includes('node') ? 'bg-green-500' :
                                                skill.name.toLowerCase().includes('python') ? 'bg-cyan-400' :
                                                skill.name.toLowerCase().includes('javascript') ? 'bg-yellow-300' :
                                                skill.name.toLowerCase().includes('typescript') ? 'bg-blue-600' :
                                                skill.name.toLowerCase().includes('mongo') ? 'bg-green-400' :
                                                skill.name.toLowerCase().includes('sql') ? 'bg-orange-400' :
                                                skill.name.toLowerCase().includes('express') ? 'bg-gray-400' :
                                                skill.name.toLowerCase().includes('django') ? 'bg-green-600' :
                                                skill.name.toLowerCase().includes('graphql') ? 'bg-pink-500' :
                                                skill.name.toLowerCase().includes('redis') ? 'bg-red-500' :
                                                skill.name.toLowerCase().includes('docker') ? 'bg-blue-500' :
                                                skill.name.toLowerCase().includes('kubernetes') ? 'bg-blue-300' :
                                                skill.name.toLowerCase().includes('aws') ? 'bg-orange-500' :
                                                skill.name.toLowerCase().includes('git') ? 'bg-orange-600' :
                                                'bg-purple-400'
                                            }`}
                                            initial={{ width: 0 }}
                                            animate={inView ? { width: `${skill.percentage}%` } : {}}
                                            transition={{ duration: 1, ease: "easeOut" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
}
