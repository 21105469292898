import React, { createContext, useState, useEffect } from 'react';

// Create theme context
export const ThemeContext = createContext({
    darkMode: true,
    toggleTheme: () => {}
});

// Create theme provider component
export const ThemeProvider = ({ children }) => {
    const [darkMode, setDarkMode] = useState(true);

    // Toggle theme function
    const toggleTheme = () => {
        setDarkMode(!darkMode);
    };

    // Apply theme class to body
    useEffect(() => {
        document.body.classList.toggle('dark', darkMode);
        document.body.classList.toggle('light', !darkMode);
    }, [darkMode]);

    return (
        <ThemeContext.Provider value={{ darkMode, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;

