import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReact, faNode, faNodeJs, faPython } from '@fortawesome/free-brands-svg-icons'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Image from '../assets/bg.png';
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';

export default function Hero() {
    const { darkMode } = useContext(ThemeContext);
    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });

    return (
        <section id="hero" className={`min-h-screen flex items-center justify-center ${darkMode ? 'bg-gray-900' : 'bg-gray-50'} py-32 px-[10%] relative overflow-hidden`}>
            {/* Matrix-like background effect */}
            <div className="absolute inset-0 opacity-10">
                <div className="absolute inset-0 bg-[linear-gradient(rgba(0,255,0,0.1)_1px,transparent_1px),linear-gradient(90deg,rgba(0,255,0,0.1)_1px,transparent_1px)]" style={{backgroundSize: '20px 20px'}}></div>
                <div className="absolute inset-0 animate-pulse bg-gradient-to-br from-blue-500/10 to-purple-500/10"></div>
            </div>

            <div ref={ref} className="container mx-auto grid md:grid-cols-2 gap-16 items-center relative z-10">
                <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={inView ? { opacity: 1, x: 0 } : {}}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    className="space-y-8"
                >
                    <motion.h2 
                        className={`text-5xl md:text-6xl font-bold ${darkMode ? 'text-white' : 'text-gray-900'}`}
                        initial={{ opacity: 0, y: 20 }}
                        animate={inView ? { opacity: 1, y: 0 } : {}}
                        transition={{ delay: 0.2, duration: 0.6 }}
                    >
                        <span className="text-green-400">&lt;</span>
                        Dawit Yetmgeta
                        <span className="text-green-400">/&gt;</span>
                    </motion.h2>
                    
                    <motion.h3 
                        className="text-3xl md:text-4xl text-blue-400 font-semibold"
                        initial={{ opacity: 0, y: 20 }}
                        animate={inView ? { opacity: 1, y: 0 } : {}}
                        transition={{ delay: 0.4, duration: 0.6 }}
                    >
                        Full Stack Developer
                    </motion.h3>
                    
                    <motion.p 
                        className={`text-xl ${darkMode ? 'text-gray-300' : 'text-gray-600'} max-w-xl`}
                        initial={{ opacity: 0, y: 20 }}
                        animate={inView ? { opacity: 1, y: 0 } : {}}
                        transition={{ delay: 0.6, duration: 0.6 }}
                    >
                        Full Stack Developer passionate about creating end-to-end web solutions. 
                        Experienced in building responsive front-end interfaces with React and robust 
                        back-end systems using Node.js and PHP. Committed to delivering high-quality, user-centric 
                        applications with clean code and modern best practices.
                    </motion.p>
                    <motion.div 
                        className="flex justify-start space-x-8"
                        initial={{ opacity: 0, y: 20 }}
                        animate={inView ? { opacity: 1, y: 0 } : {}}
                        transition={{ delay: 0.7, duration: 0.6 }}
                    >
                        <a 
                            href="https://github.com/DaveHR1998" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className={`text-3xl ${darkMode ? 'text-gray-300 hover:text-green-400' : 'text-gray-600 hover:text-green-400'} transition-colors duration-300`}
                        >
                            <i className="fab fa-github"></i>
                        </a>
                        <a 
                            href="https://linkedin.com/in/dave-hr" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className={`text-3xl ${darkMode ? 'text-gray-300 hover:text-green-400' : 'text-gray-600 hover:text-green-400'} transition-colors duration-300`}
                        >
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a 
                            href="https://twitter.com/DaveHR1998" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className={`text-3xl ${darkMode ? 'text-gray-300 hover:text-green-400' : 'text-gray-600 hover:text-green-400'} transition-colors duration-300`}
                        >
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a 
                            href="https://t.me/davehr18"
                            target="_blank"
                            rel="noopener noreferrer" 
                            className={`text-3xl ${darkMode ? 'text-gray-300 hover:text-green-400' : 'text-gray-600 hover:text-green-400'} transition-colors duration-300`}
                        >
                            <i className="fab fa-telegram"></i>
                        </a>
                        <a 
                            href="mailto:davehr1998@gmail.com"
                            className={`text-3xl ${darkMode ? 'text-gray-300 hover:text-green-400' : 'text-gray-600 hover:text-green-400'} transition-colors duration-300`}
                        >
                            <i className="fas fa-envelope"></i>
                        </a>
                    </motion.div>
                    <motion.button
                        className="px-10 py-4 bg-green-500 text-white rounded-full font-semibold text-lg
                                 hover:bg-green-600 transform hover:scale-105 transition-all duration-300
                                 shadow-lg hover:shadow-xl hover:shadow-green-500/30"
                        initial={{ opacity: 0, y: 20 }}
                        animate={inView ? { opacity: 1, y: 0 } : {}}
                        transition={{ delay: 0.8, duration: 0.6 }}
                        whileHover={{ 
                            rotate: 360,
                            transition: {
                                duration: 0.8,
                                ease: "easeInOut"
                            }
                        }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <a href="tel:+251953399990">Let's Connect!</a>
                    </motion.button>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    animate={inView ? { opacity: 1, x: 0 } : {}}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    className="relative"
                >
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-full filter blur-xl"></div>
                    <img 
                        src={Image}
                        alt="Dawit Yetmgeta"
                        className="w-full h-[500px] object-contain relative z-10"
                    /> 
                </motion.div>
            </div>
        </section>
    );
}
