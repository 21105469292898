import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ThemeContext } from '../context/ThemeContext';

export default function Footer() {
    const { darkMode } = useContext(ThemeContext);
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const currentYear = new Date().getFullYear();

    return (
        <footer className={`${darkMode ? 'bg-gray-900 text-gray-300' : 'bg-gray-100 text-gray-600'} py-8`}>
            <div className="container mx-auto px-[10%]">
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, y: 20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6 }}
                    className="flex flex-col md:flex-row justify-between items-center"
                >
                    <div className="text-center md:text-left mb-4 md:mb-0">
                        <h3 className="text-xl font-bold mb-2">
                            <span className="text-green-400">&lt;</span>
                            Dawit Yetmgeta
                            <span className="text-green-400">/&gt;</span>
                        </h3>
                        <p className="text-sm">Full Stack Developer</p>
                    </div>

                    <div className="flex space-x-6 mb-4 md:mb-0">
                        <a 
                            href="https://github.com/DaveHR1998"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-green-400 transition-colors duration-300"
                        >
                            <i className="fab fa-github text-xl"></i>
                        </a>
                        <a 
                            href="https://linkedin.com/in/dave-hr"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-green-400 transition-colors duration-300"
                        >
                            <i className="fab fa-linkedin text-xl"></i>
                        </a>
                        <a 
                            href="https://twitter.com/DaveHR1998"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-green-400 transition-colors duration-300"
                        >
                            <i className="fab fa-twitter text-xl"></i>
                        </a>
                        <a 
                            href="mailto:davehr1998@gmail.com"
                            className="hover:text-green-400 transition-colors duration-300"
                        >
                            <i className="fas fa-envelope text-xl"></i>
                        </a>
                    </div>

                    <div className="text-sm text-center md:text-right">
                        <p>&copy; {currentYear} All rights reserved.</p>
                        <p>Designed & Built by Dawit Yetmgeta</p>
                    </div>
                </motion.div>
            </div>
        </footer>
    );
}
