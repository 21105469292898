import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faNodeJs, faJs, faHtml5, faCss3 } from '@fortawesome/free-brands-svg-icons';
import abugida from '../assets/abugida.png';
import music from '../assets/195.png';
import mountskill from '../assets/mount.png';

export default function Projects() {
    const { darkMode } = useContext(ThemeContext);
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const getIconForTech = (tech) => {
        switch(tech.toLowerCase()) {
            case 'react':
                return faReact;
            case 'node.js':
                return faNodeJs;
            case 'javascript':
                return faJs;
            case 'html':
                return faHtml5;
            case 'css':
                return faCss3;
            default:
                return null;
        }
    };

    const projects = [
        {
            id: 1,
            title: "Mount Skill",
            description: "A full-stack E-learning platform for students and teachers. Features include user authentication, course management, interactive lessons, progress tracking, and assessment tools. Done by team NovaComputech.",
            image: mountskill, 
            technologies: ["Hidden"],
            liveLink: "https://mountskill.com",
            
        },
        {
            id: 2,
            title: "195 Music",
            description: "A music streaming website with a user-friendly interface, allowing users to browse and play songs, and manage their playlists.",
            image: music, // Fixed image reference
            technologies: ["React", "Node", "Firebase", "Tailwind CSS"],
            liveLink: "https://195.davehr.com/",
            
        },
        {
            id: 3,
            title: "Portfolio for Abugida",
            description: "A professional robotics training center website with dynamic content management, featuring company information, student registration portal, blog articles, and an admin dashboard for managing all content and student applications.",
            image: abugida, // Fixed image reference
            technologies: ["React","Node","Mysqli","Framer Motion", "Tailwind CSS"],
            liveLink: "https://abugidarobotics.com/",
            
        }
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { 
            opacity: 0,
            y: 20 
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: "easeOut"
            }
        }
    };

    return (
        <section id='projects' className={`py-20 ${darkMode ? 'bg-gray-900' : 'bg-white'} px-[10%]`}>
            <div ref={ref} className="container mx-auto">
                <motion.h2 
                    className={`text-4xl font-bold text-center mb-12 ${darkMode ? 'text-white' : 'text-gray-900'}`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6 }}
                >
                    <span className="text-green-400">&lt;</span>
                    Projects
                    <span className="text-green-400">/&gt;</span>
                </motion.h2>

                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="grid md:grid-cols-2 lg:grid-cols-3 gap-8"
                >
                    {projects.map(project => (
                        <motion.div
                            key={project.id}
                            variants={itemVariants}
                            className={`${darkMode ? 'bg-gray-800' : 'bg-gray-100'} rounded-lg overflow-hidden shadow-lg`}
                            whileHover={{ y: -5 }}
                        >
                            <div className="relative overflow-hidden group">
                                <img 
                                    src={project.image} 
                                    alt={project.title}
                                    className="w-full h-48 object-cover transform group-hover:scale-110 transition duration-500"
                                />
                                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                                    <div className="space-x-4">
                                        <a 
                                            href={project.liveLink} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            className="bg-green-400 text-white px-4 py-2 rounded-lg hover:bg-green-500 transition duration-300"
                                        >
                                            View Live
                                        </a>
                                        <a 
                                            href={project.githubLink} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            className="bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
                                        >
                                            GitHub
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="p-6">
                                <h3 className={`text-xl font-bold mb-2 ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                                    {project.title}
                                </h3>
                                <p className={`mb-4 ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                    {project.description}
                                </p>
                                <div className="flex flex-wrap gap-2">
                                    {project.technologies.map((tech, index) => {
                                        const icon = getIconForTech(tech);
                                        return (
                                            <span 
                                                key={index}
                                                className="px-3 py-1 bg-green-400 text-white rounded-full text-sm flex items-center gap-2"
                                            >
                                                {icon && <FontAwesomeIcon icon={icon} className="text-xs" />}
                                                {tech}
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
}
