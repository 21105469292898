import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import reactBlog from '../assets/image.png';
import nextjsBlog from '../assets/next.webp'; // Added missing import
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';

const cardVariants = {
    hidden: {
        opacity: 0,
        scale: 0.9,
        y: 50
    },
    visible: {
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
            duration: 0.6,
            ease: "easeOut"
        }
    },
    hover: {
        scale: 1.03,
        boxShadow: "0 20px 30px rgba(0,0,0,0.2)",
        transition: {
            duration: 0.3
        }
    }
};

const glowVariants = {
    initial: {
        opacity: 0.5,
        scale: 1
    },
    animate: {
        opacity: [0.5, 1, 0.5],
        scale: [1, 1.1, 1],
        transition: {
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
        }
    }
};

const tagVariants = {
    initial: {
        opacity: 0,
        x: -10
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.4
        }
    },
    hover: {
        scale: 1.1,
        color: "#4ade80",
        transition: {
            duration: 0.2
        }
    }
};

const imageVariants = {
    hidden: {
        opacity: 0,
        scale: 0.8,
        rotate: -5
    },
    visible: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: {
            duration: 0.5,
            ease: "easeOut"
        }
    },
    hover: {
        scale: 1.05,
        rotate: 2,
        transition: {
            duration: 0.3
        }
    }
};

const textRevealVariants = {
    hidden: {
        opacity: 0,
        y: 20
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.5,
            ease: "easeOut"
        }
    }
};

export default function Blog() {
    const { darkMode } = useContext(ThemeContext); // Added missing darkMode context
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const blogPosts = [
        {
            id: 1,
            title: "Getting Started with React Development",
            intro: "A comprehensive guide for beginners looking to start their journey with React.",
            image: reactBlog,
            date: "November 10, 2024",
            author: "Dawit Yetmgeta",
            content: {
                mainContent: "React has become one of the most popular JavaScript libraries for building user interfaces...",
                subHeadings: [
                    {
                        title: "Setting Up Your Development Environment",
                        content: "First, ensure you have Node.js installed on your system..."
                    },
                    {
                        title: "Understanding React Components",
                        content: "Components are the building blocks of any React application..."
                    }
                ]
            },
            tags: ["React", "JavaScript", "Web Development"]
        },
        {
            id: 2,
            title: "Exploring the Power of Next.js",
            intro: "A deep dive into the features and benefits of Next.js for modern web development.",
            image: nextjsBlog,
            date: "November 12, 2024", 
            author: "Dawit Yetmgeta",
            content: {
                mainContent: "Next.js is a powerful framework for React applications...",
                subHeadings: [
                    {
                        title: "Server-side Rendering",
                        content: "Next.js provides built-in server-side rendering capabilities..."
                    }
                ]
            },
            tags: ["Next.js", "React", "Web Development"]
        }
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { 
            opacity: 0, 
            y: 20 
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: "easeOut"
            }
        }
    };

    return (
        <section id='blog' className={`py-20 ${darkMode ? 'bg-gray-900' : 'bg-white'} px-[10%]`}>
            <div ref={ref} className="container mx-auto ">
                <motion.h2 
                    className={`text-4xl font-bold text-center mb-12 ${darkMode ? 'text-white' : 'text-gray-900'}`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6 }}
                >
                    <span className="text-green-400">&lt;</span>
                    Blog Posts
                    <span className="text-green-400">/&gt;</span>
                </motion.h2>

                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="grid md:grid-cols-2 grid-cols-1 gap-8"
                >
                    {blogPosts.map(post => (
                        <motion.article 
                            key={post.id}
                            variants={itemVariants}
                            className={`${darkMode ? 'bg-gray-800' : 'bg-gray-100'} rounded-lg overflow-hidden shadow-lg`}
                        >
                            <div className="relative h-64">
                                <img 
                                    src={post.image} 
                                    alt={post.title}
                                    className="w-full h-full object-cover"
                                />
                                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-gray-900 p-6">
                                    <h3 className="text-2xl font-bold text-white mb-2">{post.title}</h3>
                                    <div className="flex items-center text-gray-400 text-sm">
                                        <span>{post.date}</span>
                                        <span className="mx-2">•</span>
                                        <span>{post.author}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="p-6">
                                <p className={`${darkMode ? 'text-gray-300' : 'text-gray-700'} mb-4`}>{post.intro}</p>
                                
                                <div className="space-y-4">
                                    {post.content?.subHeadings?.map((subHeading, index) => (
                                        <div key={index}>
                                            <h4 className="text-xl font-semibold text-green-400 mb-2">
                                                {subHeading.title}
                                            </h4>
                                            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{subHeading.content}</p>
                                        </div>
                                    ))}
                                </div>

                                <div className="mt-6 flex flex-wrap gap-2">
                                    {post.tags?.map((tag, index) => (
                                        <span 
                                            key={index}
                                            className={`px-3 py-1 ${darkMode ? 'bg-gray-700' : 'bg-gray-200'} text-green-400 rounded-full text-sm`}
                                        >
                                            #{tag}
                                        </span>
                                    ))}
                                </div>

                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="mt-6 px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                                >
                                    Read More
                                </motion.button>
                            </div>
                        </motion.article>
                    ))}
                </motion.div>
            </div>
        </section>
    );
}
