import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faCode, faDatabase, faCloud, faUsers, faLaptopCode, faMobile, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';



const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1
        }
    }
};

const itemVariants = {
    hidden: { 
        opacity: 0,
        y: 20
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.5,
            ease: "easeOut"
        }
    }
};

const iconVariants = {
    hidden: { 
        scale: 0,
        rotate: -180
    },
    visible: {
        scale: 1,
        rotate: 0,
        transition: {
            type: "spring",
            stiffness: 200,
            damping: 15
        }
    },
    hover: {
        scale: 1.2,
        rotate: 360,
        transition: {
            duration: 0.3
        }
    }
};
const cardVariants = {
    initial: {
        scale: 1,
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
    },
    hover: {
        scale: 1.05,
        boxShadow: "0 10px 20px rgba(0, 128, 0, 0.2)",
        transition: {
            type: "spring",
            stiffness: 300,
            damping: 20
        }
    },
    tap: {
        scale: 0.95,
        boxShadow: "0 2px 4px rgba(0, 128, 0, 0.1)",
        transition: {
            type: "spring",
            stiffness: 500,
            damping: 15
        }
    }
};

const descriptionVariants = {
    initial: {
        opacity: 0,
        height: 0
    },
    hover: {
        opacity: 1,
        height: "auto",
        transition: {
            duration: 0.3
        }
    }
};

const titleVariants = {
    hover: {
        color: "#4ade80", // text-green-400
        transition: {
            duration: 0.2
        }
    }
};

export default function Services() {
    const { darkMode } = useContext(ThemeContext);
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const services = [
        {
            icon: faServer,
            title: "Backend Development",
            description: "Building robust, scalable server-side applications using Node.js, Express, and Python/Django"
        },
        {
            icon: faCode,
            title: "Frontend Development",
            description: "Creating responsive and interactive user interfaces with React.js and modern CSS frameworks"
        },
        {
            icon: faDatabase,
            title: "Database Design",
            description: "Designing and optimizing both SQL and NoSQL databases for optimal performance"
        },
        {
            icon: faCloud,
            title: "Cloud Services",
            description: "Deploying and managing applications on AWS, Azure, and other cloud platforms"
        },
        {
            icon: faUsers,
            title: "Team Collaboration",
            description: "Leading development teams and implementing agile methodologies"
        },
        {
            icon: faLaptopCode,
            title: "Full Stack Solutions",
            description: "End-to-end development of web applications from concept to deployment"
        },
        {
            icon: faMobile,
            title: "API Development",
            description: "Creating RESTful and GraphQL APIs for seamless integration"
        },
        {
            icon: faGraduationCap,
            title: "Programming Mentorship",
            description: "Guiding aspiring developers through personalized learning paths and code reviews"
        }
    ];

    return (
        <section className={`py-20 ${darkMode ? 'bg-gray-900' : 'bg-white'} px-[10%]`}>
            <div ref={ref} className="container mx-auto">
                <motion.h2 
                    className={`text-4xl font-bold text-center ${darkMode ? 'text-white' : 'text-gray-900'} mb-16`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6 }}
                >
                    <span className="text-green-400">Services</span> I Offer
                </motion.h2>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {services.map((service, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            animate={inView ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.6, delay: index * 0.1 }}
                            className={`relative ${darkMode ? 'bg-gray-800' : 'bg-gray-100'} rounded-lg p-6 pt-12 shadow-lg hover:shadow-xl transition-all duration-300`}
                        >
                            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
                                <div className="relative">
                                    {/* Hexagon Background */}
                                    <div className="w-16 h-16 bg-green-500 transform rotate-45 absolute left-1/2 -translate-x-1/2"></div>
                                    {/* Icon */}
                                    <div className="relative z-10 w-16 h-16 flex items-center justify-center">
                                        <FontAwesomeIcon 
                                            icon={service.icon} 
                                            className="text-white text-2xl transform -rotate-45"
                                        />
                                    </div>
                                </div>
                            </div>

                            <h3 className="text-xl font-semibold text-green-400 text-center mb-4 mt-4">
                                {service.title}
                            </h3>
                            <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'} text-center`}>
                                {service.description}
                            </p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
}
