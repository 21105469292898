import Image from "../assets/icon.png";
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { motion } from 'framer-motion';
const navVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.5,
            ease: "easeOut"
        }
    }
};

const linkVariants = {
    hover: {
        scale: 1.1,
        color: "#4ade80", // text-green-400
        transition: {
            duration: 0.2
        }
    },
    tap: {
        scale: 0.95
    }
};

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1
        }
    }
};

const itemVariants = {
    hidden: { 
        opacity: 0,
        y: 20
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.5,
            ease: "easeOut"
        }
    }
};

export default function Header() {
    const { darkMode, toggleTheme } = useContext(ThemeContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const sections = [
        { id: "hero", label: ".home()" },
        { id: "projects", label: ".projects()" },
        { id: "experience", label: ".experience()" },
        { id: "blog", label: ".blog()" },
        { id: "contact", label: ".contact()" }
    ];

    return (
        <header className={`fixed w-full top-0 z-50 shadow-lg px-[10%] ${darkMode ? 'bg-gray-900' : 'bg-white'}`}>
            <nav className="container mx-auto px-6 py-4">
                <div className="flex items-center justify-between">
                    <motion.div 
                        className="flex items-center gap-3 text-xl md:text-2xl font-mono text-green-400"
                        variants={itemVariants}
                        initial="hidden"
                        animate="visible"
                    >
                        <img src={Image} alt="Dawit Yetmgeta" className="h-6 md:h-8 rounded-lg border-2 border-green-400 p-1" />
                        <span className="flex items-center">
                            <span className="text-blue-400">&lt;</span>
                            DY
                            <span className="text-blue-400">/&gt;</span>
                        </span>
                    </motion.div>
                    
                    <motion.div 
                        className="hidden md:flex space-x-8 font-mono"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                    >
                        {sections.map((section) => (
                            <motion.a 
                                key={section.id}
                                variants={itemVariants} 
                                href={`#${section.id}`} 
                                className="text-green-400 hover:text-green-300 transition duration-300"
                            >
                                {section.label}
                            </motion.a>
                        ))}
                        <motion.button
                            variants={itemVariants}
                            onClick={toggleTheme}
                            className="text-green-400 hover:text-green-300 transition duration-300"
                        >
                            <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
                        </motion.button>
                    </motion.div>

                    <button 
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="md:hidden text-green-400 focus:outline-none"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>

                    {isMenuOpen && (
                        <motion.div 
                            className={`md:hidden absolute top-full left-0 right-0 shadow-lg ${darkMode ? 'bg-gray-900' : 'bg-white'}`}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.2 }}
                        >
                            <div className="flex flex-col space-y-4 px-6 py-4 font-mono">
                                {sections.map((section) => (
                                    <a 
                                        key={section.id}
                                        href={`#${section.id}`} 
                                        className="text-green-400 hover:text-green-300 transition duration-300"
                                    >
                                        {section.label}
                                    </a>
                                ))}
                                <button
                                    onClick={toggleTheme}
                                    className="text-green-400 hover:text-green-300 transition duration-300 text-left"
                                >
                                    <FontAwesomeIcon icon={darkMode ? faSun : faMoon} className="mr-2" />
                                    .toggleTheme()
                                </button>
                            </div>
                        </motion.div>
                    )}
                </div>
            </nav>
        </header>
    );
}
