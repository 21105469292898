import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';

export default function Experience() {
    const { darkMode } = useContext(ThemeContext);
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });
    const [activeIndex, setActiveIndex] = React.useState(0);

    const timelineVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.5 }
        }
    };

    const circleVariants = {
        hidden: { scale: 0 },
        visible: {
            scale: 1,
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 20
            }
        }
    };

    const progressVariants = {
        initial: { height: "0%" },
        animate: (index) => ({
            height: `${(index + 1) * (100 / experiences.length)}%`,
            transition: {
                duration: 0.5,
                ease: "easeInOut"
            }
        })
    };

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry, idx) => {
                    if (entry.isIntersecting) {
                        setActiveIndex(parseInt(entry.target.dataset.index));
                    }
                });
            },
            { threshold: 0.5 }
        );

        document.querySelectorAll('.experience-item').forEach((item) => {
            observer.observe(item);
        });

        return () => observer.disconnect();
    }, []);

    const experiences = [
        {
            title: "CEO & Lead Developer",
            company: "Nova Computech",
            period: "2022 - Present",
            description: "As CEO of Nova Computech, I lead a dynamic team delivering innovative software development and networking solutions. I oversee strategic planning, client relationships, and technical direction while actively contributing to complex development projects. Under my leadership, we've successfully delivered enterprise-scale applications, cloud infrastructure solutions, and comprehensive networking services to clients across multiple industries."
        },
        {
            title: "Full Stack Developer",
            company: "Freelance",
            period: "2022 - Present",
            description: "As a freelance full stack web developer, I have had the opportunity to work on a variety of web development projects for clients across different industries. I have provided end-to-end web development services, from initial concept and design to implementation and deployment. My experience as a freelancer has allowed me to adapt to the specific needs of each client, delivering custom web solutions tailored to their requirements."
        },
        {
            title: "Lecturer",
            company: "St. Mary's University",
            period: "2022 - Present",
            description: "As a lecturer in computer science, I am passionate about sharing my knowledge and expertise with students. I am dedicated to creating an engaging learning environment where students can explore the fascinating world of computer science. Through interactive lectures, hands-on projects, and real-world examples, I aim to inspire and empower the next generation of computer scientists."
        },
        {
            title: "Content Creator",
            company: "MountScript",
            period: "2022 - Present",
            description: "As a content creator on the YouTube platform MountScript, I produce engaging and informative videos on a variety of topics. I focus on creating high-quality, visually appealing content that resonates with my audience. My videos cover diverse subjects, providing valuable insights, and educational content. I actively engage with my viewers, fostering a sense of community and interaction."
        },
        {
            title: "Web Developer Intern",
            company: "Abugida Robotics Center",
            period: "2021 - 2022",
            description: "Contributed to the development of an innovative e-learning platform, collaborating on the implementation of user-friendly interfaces, interactive course content, and assessment features. Utilized a variety of web technologies to create a dynamic and engaging learning environment. Worked closely with cross-functional teams to ensure seamless integration of front-end and back-end components."
        }
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3
            }
        }
    };

    const itemVariants = {
        hidden: { 
            opacity: 0,
            y: 50
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.8,
                ease: "easeOut"
            }
        }
    };

    return (
        <section id='experience' className={`py-20 ${darkMode ? 'bg-gray-900' : 'bg-white'} px-[10%]`}>
            <div ref={ref} className="container mx-auto">
                <motion.h2 
                    className={`text-4xl font-bold text-center mb-16 ${darkMode ? 'text-white' : 'text-gray-900'}`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6 }}
                >
                    <span className="text-green-400">&lt;</span>
                    Professional Journey
                    <span className="text-green-400">/&gt;</span>
                </motion.h2>

                <motion.div
                    className="space-y-12"
                    variants={containerVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                >
                    {experiences.map((exp, index) => (
                        <motion.div
                            key={index}
                            variants={itemVariants}
                            className={`${darkMode ? 'bg-gray-800' : 'bg-gray-50'} p-8 rounded-lg shadow-xl hover:shadow-green-500/20 
                                     transition-all duration-500 hover:scale-[1.02] relative overflow-hidden
                                     border-l-4 border-green-400`}
                        >
                            <div className="absolute top-0 right-0 w-32 h-32 bg-green-400/5 rounded-full -mr-16 -mt-16"></div>
                            <div className="relative z-10">
                                <div className="flex flex-wrap items-center gap-4 mb-4">
                                    <h3 className="text-2xl font-bold text-green-400">{exp.title}</h3>
                                    <span className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>|</span>
                                    <span className="text-blue-400 font-semibold">{exp.company}</span>
                                </div>
                                <div className="mb-4">
                                    <span className={`${darkMode ? 'text-gray-300 bg-gray-700' : 'text-gray-600 bg-gray-200'} px-3 py-1 rounded-full text-sm`}>
                                        {exp.period}
                                    </span>
                                </div>
                                <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'} leading-relaxed`}>
                                    {exp.description}
                                </p>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
}
