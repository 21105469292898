import { ThemeProvider } from './context/ThemeContext';
import Hero from './components/hero';
import Projects from './components/projects';
import Services from './components/services';
import Skills from './components/skills';
import Experience from './components/experiance';
import Blog from './components/blog';
import Contact from './components/contact';
import Footer from './components/footer';
import Header from './components/header';
import { useState } from 'react';

function App() {
  return (
    <ThemeProvider>
      <div className="App">
        <Header />
        <Hero />
        <Projects />
        <Services />
        <Skills />
        <Experience />
        <Blog />
        <Contact />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;

